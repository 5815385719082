<template>
    <section class="widget new-updated-design">
        <vue-snotify></vue-snotify>
        <div>
            <div class="row">
                <div class="col-md-7 grid-margin grid-margin-md-0 stretch-card">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title text-left">{{ $t('notifications-list') }}</h4>
                        </div>
                        <div class="card-body new-card-body">
                            <vue-good-table
                                    mode="remote"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange"
                                    :select-options="{ enabled: true }"
                                    @on-selected-rows-change="selectionChanged"
                                    :totalRows="totalRecords"
                                    :isLoading.sync="isLoading"
                                    :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                                    :rows="rows"
                                    :columns="columns"
                                    @on-search="searchFn"
                                    :search-options="{enabled: true,searchFn: searchFn}"
                                    :row-style-class="checkFcmKey">
                                <template slot="table-row" slot-scope="props">
                                                             <span
                                                                     v-if="props.column.field === 'action'"
                                                                     class="text-nowrap"
                                                             >
                                                                 <b-button size="sm" @click="editSpotter(props.row.id)"
                                                                           class="mr-1 btn-info text-white">
                                                                View Profile
                                                            </b-button>
                                                            <b-button size="sm" @click="deleteSpotter(props.row.id)"
                                                                      class="mr-1 btn-danger text-white">
                                                                Delete
                                                            </b-button>
                                                             </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 grid-margin grid-margin-md-0 stretch-card">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title text-left">{{ $t('notifications') }}</h4>
                        </div>
                        <form @submit.stop.prevent="sendNotifications">
                            <div class="card-body new-card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- <b-form-group label="Title*" label-for="title">
                                            <b-form-input
                                                    type="text"
                                                    id="title"
                                                    name="title"
                                                    autocomplete="new-password"
                                                    placeholder="Title"
                                                    v-model="$v.title.$model"
                                                    :state="$v.title.$dirty ? !$v.title.$error : null"
                                                    aria-describedby="input-1-live-feedback"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">This is a
                                                required
                                                field.
                                            </b-form-invalid-feedback>
                                        </b-form-group> -->
                                    </div>
                                </div>
                                <b-form-textarea
                                        :placeholder="$t('Enter your notification body...')" 
                                        rows="30"
                                        max-rows="6"
                                        v-model="$v.text.$model"
                                        :state="$v.text.$dirty ? !$v.text.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                ></b-form-textarea>
                                <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                                </b-form-invalid-feedback>
                                <!-- <div class="row mt-4">
                                    <div class="col-md-3">
                                        <img :src="url" alt="" width="100px" height="100px" class="mb-1">
                                    </div>
                                    <div class="col-md-9">
                                        <b-form-group label="Upload Icon" label-for="input8">
                                            <b-form-file ref="fileInput" class="mt-2"
                                                         accept="image/png, image/jpeg"
                                                         id="input8"
                                                         placeholder="Choose a file..."
                                                         @change="onFileSelected"></b-form-file>
                                        </b-form-group>
                                        <a href="javascript:void(0)" @click="removeImage">Remove Image</a>
                                    </div>
                                </div> -->
                            </div>
                            <div class="row float-right">
                                <!-- <div class="col-md-9 mt-3 text-right">Send to all checked</div> -->
                                <div class="col-md-12">
                                    <b-button type="submit" variant="success" class="mr-4 mb-3"
                                        :disabled="isDisabled || isLoading2">
                                        <template v-if="isLoading2">
                                        <i class="fas fa-spinner fa-spin"></i> {{$t('sending')}}...
                                    </template>
                                    <template v-else>
                                        {{ $t('send-to-selected-btn') }}
                                    </template>
                                    </b-button>
                                    <!-- <b-button type="submit" variant="success" class="mr-4 mb-3"
                                              :disabled="isDisabled">Submit
                                    </b-button> -->
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    </section>
</template>
<script>
    import API from '@/api'
    import moment from 'moment'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import Vue from "vue";
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)

    export default {
        name: 'notifications',
        mixins: [validationMixin],
        components: {},
        data() {
            return {
                text: '',
                // title: '',
                icon: [],
                url: '',
                serverParams: {
                    // a map of column filters example: {name: 'john', age: '20'}
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    columnFilters: {},
                    sort: [
                        {
                            field: 'user_client_settings.id', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Phone',
                        field: 'mobile_phone',
                        sortable: true,
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        sortable: true,
                    },
                    {
                        label: 'Location',
                        field: "address",
                        sortable: true,
                    },
                ],
                isLoading: false,
                isLoading2: false,
                rows: [],
                user_client_ids: [],
                totalRecords: 0,
                isDisabled: false,
            };
        },
        validations: {
            text: {
                required,
            },
            // title: {
            //     required,
            // },
        },
        methods: {
            resetForm() {
                this.form.date_from = '';
                this.form.date_to = '';
            },
            loadItems() {
                this.isLoading = true;
                API.getAllNotificationSpotters(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        console.log('rows, ',this.rows);
                    },
                    err => {
                    }
                )
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            selectionChanged(param) {
                this.user_client_ids = [];
                param.selectedRows.forEach((value, index) => {
                    const data = {
                        email: value.email,
                        user_id: value.user_id,
                    }
                    this.user_client_ids.push(data)
                });
                // console.log(this.user_client_ids);
            },
            sendNotifications() {
                this.$v.text.$touch();
                // this.$v.title.$touch();
                if (this.$v.text.$anyError) {
                    return;
                }
                if (this.user_client_ids.length === 0) {
                    this.$swal({
                        title: 'Error',
                        text: "Please select users first!",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        // confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                    })
                } else {
                    this.isDisabled = true;
                    this.isLoading2 = true;
                    let data = {
                        text: this.text,
                        icon: this.icon,
                        // title: this.title,
                        users: this.user_client_ids,
                        client_id: JSON.parse(localStorage.getItem('client_id')),
                    }
                    API.sendSpotterNotifications(
                        data,
                        data => {
                            this.isDisabled = false;
                            this.isLoading2 = false;
                            this.$snotify.success(data.message, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        },
                        err => {
                            this.isDisabled = false;
                            this.isLoading2 = false;
                            this.$snotify.error(err, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        })
                }
            },
            // onFileSelected(event) {
            //     this.icon = event.target.files[0]
            //     this.url = URL.createObjectURL(this.icon)
            // },
            // removeImage() {
            //     this.icon = [];
            //     this.url = '';
            // },
             checkFcmKey(row) {
                 console.log('row.device_id', row.device_id);
                return row.device_id !== null ? 'noti-check' : '';
            },
        }, computed: {},
        mounted() {
            this.isLoading = true;
            this.loadItems();
        },
        beforeMount() {
        },
    }
</script>


<style>
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>